import Router from './createRouter';
import ROUTES from './constants';
import { templateRenderer, clearPopup, showPopup, templateModal, modalWindow, trialInfo } from '../utils';
import {
    client as BraintreeClient,
    applePay,
    paypalCheckout,
    googlePayment
} from 'braintree-web';
import authService from '../auth';
import billingSDK from '../billing';
import jwtDecode from 'jwt-decode';
import { STORE_NAME, CLIENT_AUTHORIZATION, MERCHANT_PAYPAL_ID, MERCHANT_ID, ENVIRONMENT } from '../constants/payment';

const popupFailedModal = templateModal('popup_payment_error');
const failedModal = () => {
    modalWindow(popupFailedModal).show();
};

const onActivatePaymentType = option => document.querySelector(`.${option}-radio`).removeAttribute('disabled');

const braintreeInit = ({ planPrice }, cb) => {
    let paymentsClient = null;
    // eslint-disable-next-line no-undef
    gPayLoaded.promise.then(() => {
        paymentsClient = new google.payments.api.PaymentsClient({ // eslint-disable-line no-undef
            environment: ENVIRONMENT
        });
    });
    // eslint-disable-next-line no-undef
    BraintreeClient.create({
        authorization: CLIENT_AUTHORIZATION
    }, (clientErr, clientInstance) => {
        if (clientErr) {
            console.error('Error creating client:', clientErr);
            return;
        };
        // eslint-disable-next-line no-undef
        googlePayment.create({
            client: clientInstance,
            googlePayVersion: 2,
            googleMerchantId: MERCHANT_ID
        }, function(googlePaymentErr, googlePaymentInstance) {
            paymentsClient.isReadyToPay({
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
                existingPaymentMethodRequired: true // Optional
            }).then(function(response) {
                if (response.result) {
                    onActivatePaymentType('googlepay');
                    document.querySelector('.gpay-box').addEventListener('click', function(event) {
                        event.preventDefault();
                        // eslint-disable-next-line no-undef
                        const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                            transactionInfo: {
                                currencyCode: 'USD',
                                totalPriceStatus: 'FINAL',
                                totalPrice: planPrice
                            }
                        });
                        const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                        cardPaymentMethod.parameters.billingAddressRequired = true;
                        cardPaymentMethod.parameters.billingAddressParameters = {
                            format: 'FULL',
                            phoneNumberRequired: true
                        };

                        paymentsClient.loadPaymentData(paymentDataRequest).then(function(paymentData) {
                            googlePaymentInstance.parseResponse(paymentData, function(err, result) {
                                if (err) {
                                    clearPopup();
                                    console.log(err);
                                }
                                // eslint-disable-next-line standard/no-callback-literal
                                cb({ nonce: result.nonce }, 'GooglePay');
                            });
                        }).catch(function(err) {
                            clearPopup();
                            failedModal();
                            // eslint-disable-next-line no-undef
                            gtag('event', 'GPay payment failed');
                            console.log('error', err);
                        });
                    });
                }
            }).catch(function(err) {
                console.log('error', err);
            });
        });
        /* eslint-disable no-undef */
        paypalCheckout.create({
            client: clientInstance,
            merchantAccountId: MERCHANT_PAYPAL_ID
        }, (paypalCheckoutErr, paypalCheckoutInstance) => {
            /* eslint-disable no-useless-return */
            if (paypalCheckoutErr) {
                console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
                return;
            }
            /* eslint-disable space-before-function-paren */
            paypalCheckoutInstance.loadPayPalSDK({
                vault: true
            }, () => {
                paypal.Buttons({

                    style: {
                        layout: 'horizontal',
                        color: 'silver',
                        height: 55,
                        label: 'paypal',
                        tagline: true
                    },

                    fundingSource: paypal.FUNDING.PAYPAL,

                    createBillingAgreement: () => {
                        showPopup();
                        return paypalCheckoutInstance.createPayment({
                            flow: 'vault',
                            displayName: STORE_NAME
                        });
                    },

                    onApprove: (data, actions) => { /* eslint-disable handle-callback-err */
                        return paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
                            /* eslint-disable standard/no-callback-literal */
                            cb({ nonce: payload.nonce }, 'PayPal');
                        });
                    },

                    onCancel: (data) => {
                        clearPopup();
                        gtag('event', 'PayPal payment canceled'); // eslint-disable-line no-undef
                        console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2));
                    },

                    onError: (err) => {
                        clearPopup();
                        failedModal();
                        gtag('event', 'PayPal payment failed'); // eslint-disable-line no-undef
                        console.error('PayPal error', err);
                    },

                    onClick: () => {
                        gtag('event', 'Subscribe with Paypal clicked'); // eslint-disable-line no-undef
                    }
                }).render('#paypal-button-selector').then(() => {
                    onActivatePaymentType('paypal');
                    clearPopup();
                });
            });
        });
        /* eslint-disable no-undef */
        window.ApplePaySession &&
        ApplePaySession.supportsVersion(3) &&
        ApplePaySession.canMakePayments() &&
        applePay.create({
            client: clientInstance
        }, (applePayErr, applePayInstance) => {
            if (applePayErr) {
                clearPopup();
                console.error('Error creating applePayInstance:', applePayErr);
                return;
            };
            // eslint-disable-next-line no-undef
            ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier)
                .then(function(canMakePaymentsWithActiveCard) {
                    if (canMakePaymentsWithActiveCard) {
                        onActivatePaymentType('applepay');
                        document.querySelector('.apple-box').addEventListener('click', () => {
                            applePayButtonClicked(applePayInstance, planPrice, cb);
                        });
                    }
                })
                .catch(error => {
                    clearPopup();
                    console.log('canMakePaymentsWithActiveCard:', error);
                });
        });
    });
};

const applePayButtonClicked = (applePayInstance, planPrice, cb) => {
    const paymentRequest = applePayInstance.createPaymentRequest({
        total: {
            label: STORE_NAME,
            amount: planPrice
        },
        requiredBillingContactFields: ['email']
    });

    const session = new ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = (event) => {
        applePayInstance.performValidation({
            validationURL: event.validationURL,
            displayName: STORE_NAME
        }, (err, merchantSession) => {
            if (err) {
                clearPopup();
                console.log('Apple Pay failed to load.', err);
                return;
            }
            session.completeMerchantValidation(merchantSession);
        });
    };

    session.onpaymentauthorized = (event) => {
        applePayInstance.tokenize({
            token: event.payment.token
        }, (tokenizeErr, payload) => {
            if (tokenizeErr) {
                clearPopup();
                failedModal();
                gtag('event', 'Apple Pay payment failed'); // eslint-disable-line no-undef
                console.log('Error tokenizing Apple Pay:', tokenizeErr);
                session.completePayment(ApplePaySession.STATUS_FAILURE); // eslint-disable-line no-undef
                return;
            }
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            // eslint-disable-next-line standard/no-callback-literal
            cb({ nonce: payload.nonce }, 'ApplePay');
        });
    };

    session.begin();
};

const getPlan = (data, id) => {
    const { plans } = data;
    return plans.find(plan => plan.id === id);
};

export default path => {
    const paymentTemplate = templateRenderer('payment');
    return {
        [`${path}/:id`]: {
            uses: (params) => {
                Router.updatePageLinks();
                showPopup();
                billingSDK.getPaymentPlans()
                    .then(response => {
                        if (response.message) {
                            clearPopup();
                            Router.navigate(ROUTES.SIGNIN);
                        } else {
                            const { data } = response;
                            const plan = getPlan(data, params.id);
                            trialInfo(plan.trialDuration, plan.price);
                            const btn = document.querySelector('.button_yellow');
                            btn.setAttribute('href', '#billing/' + params.id);
                            braintreeInit({
                                planPrice: plan.price
                            }, ({ nonce }, metod) => {
                                billingSDK.paymentCheckout({
                                    nonce,
                                    planId: plan.id
                                }).then((response) => {
                                    const { error } = response;
                                    clearPopup();
                                    if (error) {
                                        failedModal();
                                        console.log('pay failed');
                                    } else {
                                        const user = authService.getUser(jwtDecode);
                                        // eslint-disable-next-line camelcase
                                        const { plan_id, subscription_id } = response.data;
                                        gtag('event', 'checkout_completed',
                                            {
                                                eventCategory: 'purchases',
                                                eventLabel: plan_id,
                                                SubscriptionID: subscription_id,
                                                UserID: user.id,
                                                PaymentMethod: metod,
                                                ProductID: plan_id,
                                                ProductPrice: plan.price,
                                                Source: 'Regular'
                                            });
                                        Router.navigate(ROUTES.CONGRATS);
                                    }
                                });
                            });
                        }
                    });
            },
            hooks: {
                before: done => {
                    paymentTemplate.mount();
                    done();
                },
                leave: () => {
                    paymentTemplate.unmount();
                }
            }
        }
    };
};
